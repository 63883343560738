<template>
  <div
    v-if="product"
    class="pro-coffee-detail"
    :class="{ 'pro-coffee-detail--no-map': !hasMapPosition }">
    <ProCoffeeDetail
      v-if="isProCoffee"
      :product="product"
      :event="event"
      @create:impression="createImpression" />
    <CoffeeDetail
      v-else
      :product="product"
      @create:impression="createImpression" />
  </div>
</template>

<script>
import { merge } from 'lodash';
import { SystemDialogMessage } from '@/constants/enums';
import { defineComponent, ref, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    CoffeeDetail: () => import('@/components/coffeeDetail/CoffeeDetail.vue'),
    ProCoffeeDetail: () =>
      import('@/components/coffeeDetail/ProCoffeeDetail.vue')
  },

  setup: (props, context) => {
    const product = ref(null);
    const event = ref({
      id: null,
      title: null,
      at: null
    });

    const productId = computed(() => context.root.$route.params.productId);
    const isProCoffee = computed(() => product.value?.baristas.length > 0);
    const eventId = computed(() => context.root.$route.query.event_id);
    const hasMapPosition = computed(() => !!product.value?.map_position);

    const fetchProduct = async () => {
      try {
        context.root.$_loading_start();

        const { data } = await context.root
          .$repositories('products')
          .getProduct(productId.value);
        product.value = data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    const fetchEvent = async () => {
      if (!eventId.value) return;

      const { data } = await context.root
        .$repositories('contentJoin')
        .getEvent(eventId.value);
      event.value.id = data.id;
      event.value.title = data.title;
      event.value.at = context.root.$utilities.getTimestamp();
    };

    /**
     * 感想登録 & コーヒー履歴詳細へ遷移
     */
    const createImpression = async (payload) => {
      try {
        context.root.$_loading_start();

        const mergedPayload = merge(payload, { memo: { event: event.value } });
        const { data } = await context.root
          .$repositories('products')
          .createImpression(productId.value, mergedPayload);

        // 遷移先でマイル演出が必要かどうかをクエリパラメータで引き回す
        const mileageQuery = {
          now_mileage: data.mileage.now_mileage,
          plus_mileage: data.mileage.plus_mileage,
          stage_up: Number(data.mileage.rank_up),
          has_present: Number(!!data.present[0]),
          stage_name_before: data.mileage.stage?.before.name,
          stage_name_after: data.mileage.stage?.after.name,
          stage_name_next: data.mileage.stage?.next.name,
          wait_for_load_complete: false
        };

        // 好き/う〜ん...の感想登録をした場合はコーヒーマップのプレビューを表示する
        const previewCoffeeMap = payload.is_favorable !== null;
        if (previewCoffeeMap) {
          await context.root.$utilities.sleep();
          // コーヒーマッププレビューへ遷移
          context.root.$router.push({
            path: '/mypage/coffeemap/preview',
            query: {
              product_id: productId.value,
              impression_id: data.id,
              ...mileageQuery
            }
          });
        } else {
          // コーヒー履歴詳細へ遷移
          context.root.$router.push({
            path: `/mypage/coffee-history/${data.id}`,
            query: { ...mileageQuery }
          });
        }
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    fetchProduct();
    fetchEvent();

    return {
      isProCoffee,
      hasMapPosition,
      product,
      event,
      createImpression
    };
  }
});
</script>

<style lang="scss" scoped>
.pro-coffee-detail {
  padding-bottom: 100px;

  &--no-map {
    padding-bottom: 64px;
  }

  &--has-impressions {
    padding-bottom: 24px;

    .pro-coffee-detail-impression-button {
      position: static;
      bottom: auto;
      width: auto;
    }
  }
}
</style>
