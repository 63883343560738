var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product
    ? _c(
        "div",
        {
          staticClass: "pro-coffee-detail",
          class: { "pro-coffee-detail--no-map": !_vm.hasMapPosition },
        },
        [
          _vm.isProCoffee
            ? _c("ProCoffeeDetail", {
                attrs: { product: _vm.product, event: _vm.event },
                on: { "create:impression": _vm.createImpression },
              })
            : _c("CoffeeDetail", {
                attrs: { product: _vm.product },
                on: { "create:impression": _vm.createImpression },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }